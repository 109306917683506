



























import Vue, { VueConstructor } from "vue"
import { PolicySubmissionMixin } from "@/mixins/policySubmission"
import { appendFirmsIfAdminViewingClient } from "@/util/urls"
import BillingLink from "../Billing/BillingLink.vue"
import PolicyContent from "./PolicyContent.vue"
import PolicySubmission from "./PolicySubmission.vue"

type PolicyApplicationLink = { label: string; to: string }

export default (Vue as VueConstructor<Vue & typeof PolicySubmissionMixin>).extend({
  components: { PolicyContent, BillingLink, PolicySubmission },
  mixins: [PolicySubmissionMixin],
  data() {
    return {
      formName: "Policy application",
      items: [] as PolicyApplicationLink[],
    }
  },
  async mounted() {
    this.items = [
      {
        label: "Reissue",
        to: await this.getAdminLinkIfOnAdminView(`/applications/reissue`),
      },
      {
        label: "Estates & Trusts",
        to: await this.getAdminLinkIfOnAdminView(`/applications/`),
      },
      {
        label: "Intellectual Property",
        to: await this.getAdminLinkIfOnAdminView(`/applications/intellectual-property`),
      },
      {
        label: "Mergers & Acquisitions",
        to: await this.getAdminLinkIfOnAdminView(`/applications/mergers-acquisitions`),
      },
      {
        label: "Plaintiff Personal Injury",
        to: await this.getAdminLinkIfOnAdminView(`/applications/plaintiff-personal-injury`),
      },
      {
        label: "Real Estate",
        to: await this.getAdminLinkIfOnAdminView(`/applications/real-estate`),
      },
      {
        label: "Securities",
        to: await this.getAdminLinkIfOnAdminView(`/applications/securities`),
      },
    ]
  },
  methods: {
    getAdminLinkIfOnAdminView(url: string) {
      return appendFirmsIfAdminViewingClient(url, this.$route.params.clientKey)
    },
  },
})
