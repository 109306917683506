import Vue from "vue"
import VueMask from "v-mask"
import vueDebounce from "vue-debounce"
import Vuelidate from "vuelidate"
import App from "./App.vue"
import router from "./router"
import vuetify from "./plugins/vuetify"
import { getKeycloak, configKeycloak } from "./keycloak/keycloak"
import { EventBus } from "./util/eventBus"

Vue.config.productionTip = false

Vue.use(VueMask)
Vue.use(Vuelidate)
Vue.use(vueDebounce)

configKeycloak()

function notifyTokenListener(token: string | undefined) {
  EventBus.$emit("token-updated", token) // Emit the token update on the EventBus
}

function setupTokenRefresh() {
  setInterval(() => {
    getKeycloak()
      .updateToken(70)
      .then(refreshed => {
        if (refreshed) {
          const { token } = getKeycloak()
          notifyTokenListener(token)
        }
      })
      .catch(() => {
        getKeycloak().login()
      })
  }, 180000)
}

function initializeApp() {
  getKeycloak()
    .init({ onLoad: "login-required", checkLoginIframe: false })
    .then(auth => {
      if (!auth) {
        window.location.reload()
      } else {
        // Get initial token and notify listeners
        const { token } = getKeycloak()
        notifyTokenListener(token)

        // Initialize Vue
        new Vue({
          router,
          vuetify,
          render: h => h(App),
        }).$mount("#app")

        // Setup token refresh after Vue is mounted
        setupTokenRefresh()
      }
    })
    .catch(error => {
      console.error("Failed to initialize Keycloak:", error)
      // Handle initialization error - maybe redirect to an error page
    })
}

// Replace the direct initialization with the function call
initializeApp()
