




























































































































































































































































































































































































































































































































































































































































































import Vue, { VueConstructor } from "vue"
import { DOCUMENT_CATEGORY_KEY } from "@/constants/categoryKey"
import { required } from "vuelidate/lib/validators"
import YesNoRadioGroup from "@/components/YesNoRadioGroup.vue"
import RadioGroup from "@/components/RadioGroup.vue"
import Form from "@/components/Policies/Forms/Form.vue"
import TextInput from "@/components/TextInput.vue"
import LmncSnackbar from "@/components/LmncSnackbar.vue"
import { FormSubmissionMixin } from "@/mixins/formSubmissionMixin"
import { AutofillFirmInfoMixin } from "@/mixins/autofillFirmInfo"
import { additionalAttorneyForm } from "@/constants/policyChangeForms"
import { PolicyFormDocuments } from "@/types/PolicyFormTypes"
import { scrollToElement } from "@/util/scrollUtils"
import { AutofillAddress } from "@/mixins/autoFillAddress"
import FormSection from "../Forms/FormSection.vue"
import FormSectionContent from "../Forms/FormSectionContent.vue"
import FormRow from "../Forms/FormRow.vue"
import FormStateSelect from "../Forms/FormStateSelect.vue"
import FormDatePicker from "../Forms/FormDatePicker.vue"
import FormContent from "../Forms/FormContent.vue"
import FormBody from "../Forms/FormBody.vue"
import FormDetail from "../Forms/FormDetail.vue"
import ListedQuestion from "../Forms/ListedQuestion.vue"
import ExpansionContainer from "../Forms/ExpansionContainer.vue"
import { DEFAULT_FULL_STATE_VALUE } from "../../../constants/fullStatesList"

type expansionContainerReturnValue = {
  key: string
  type: string
  value: string | PolicyFormDocuments[]
}

type AddAttorneyDocuments = {
  [key: string]: string | PolicyFormDocuments[]
}

// For some reason, mixins confuse the TS compiler, causing it to believe there is no handleSubmit method on this component,
// despite the fact that it's defined in FormSubmissionMixin. So we define it explicitly...
type AddALawyerMixins = Vue & typeof FormSubmissionMixin & typeof AutofillFirmInfoMixin & { handleSubmit: () => {} }

export default (Vue as VueConstructor<AddALawyerMixins>).extend({
  components: {
    LmncSnackbar,
    Form,
    FormSection,
    FormSectionContent,
    FormRow,
    TextInput,
    YesNoRadioGroup,
    RadioGroup,
    FormStateSelect,
    FormDatePicker,
    FormContent,
    FormBody,
    FormDetail,
    ListedQuestion,
    ExpansionContainer,
  },
  mixins: [FormSubmissionMixin, AutofillFirmInfoMixin(), AutofillAddress()],
  data: () => ({
    showInvalidLicenseSnackbar: false,
    showAdditionalLicense: false,
    genderItems: ["Male", "Female"],
    invalidLicenseInformation: false,
    formToSubmit: additionalAttorneyForm,
    userSubmissionForm: {
      type: "attorneyAddition",
      description: "Request for Additional Attorney",
      items: {
        firmname: null as string | null,
        policykey: null as string | null,
        officeaddress: null as string | null,
        city: null as string | null,
        state: null as string | null,
        zipcode: null as string | null,
        attorneyname: null as string | null,
        datejoinedaslicensedattorney: null as string | null,
        positionwithinfirm: null as string | null,
        estimatedhoursworkedperweek: null as string | null,
        areaofpractice: null as string | null,
        prioractscoverage: null as string | null,
        dateofbirth: null as string | null,
        gender: null as string | null,
        officeemail: null as string | null,
        lawschoolname: null as string | null,
        yearnorthcarolina: null as string | null,
        barnumbernorthcarolina: null as string | null,
        additonallicenseyear: null as string | null,
        additonallicensestate: null as string | null,
        additionallicensebarnumber: null as string | null,
        additonallicenseyear2: null as string | null,
        additonallicensestate2: null as string | null,
        additonallicensebarnumber2: null as string | null,
        priorcarrier: null as string | null,
        priorcarriereffdate: null as string | null,
        previousemployername: null as string | null,
        refusedadmissionind: null as string | null,
        refusedadmissiondetails: null as string | null,
        felonyconvictionind: null as string | null,
        felonyconvictiondetails: null as string | null,
        grievanceind: null as string | null,
        grievancedetails: null as string | null,
        claimsagainstind: null as string | null,
        claimsagainstdetails: null as string | null,
        erroromissionind: null as string | null,
        erroromissiondetails: null as string | null,
        insurancedeclinedind: null as string | null,
        insurancedeclineddetails: null as string | null,
        partnerassociateind: null as string | null,
        partnerassociatedetails: null as string | null,
        contractorind: null as string | null,
        contractordetails: null as string | null,
        guaranteedresultsind: null as string | null,
        guaranteedresultsdetails: null as string | null,
        outofstateind: null as string | null,
        outofstatedetails: null as string | null,
        signature: {
          newhireattorneysignature: null as string | null,
          newhireattorneysignaturedate: null as string | null,
        },
        managingattorneysignature: null as string | null,
        managingattorneysignaturedate: null as string | null,
      } as { [key: string]: any },
    },
    userSubmissionDocuments: {
      refusedadmissionDocs: [
        {
          fileName: null,
          base64Content: null,
          categoryKey: DOCUMENT_CATEGORY_KEY,
        },
      ],
      felonyconvictionDocs: [
        {
          fileName: null,
          base64Content: null,
          categoryKey: DOCUMENT_CATEGORY_KEY,
        },
      ],
      grievanceDocs: [
        {
          fileName: null,
          base64Content: null,
          categoryKey: DOCUMENT_CATEGORY_KEY,
        },
      ],
      claimsagainstDocs: [
        {
          fileName: null,
          base64Content: null,
          categoryKey: DOCUMENT_CATEGORY_KEY,
        },
      ],
      erroromissionDocs: [
        {
          fileName: null,
          base64Content: null,
          categoryKey: DOCUMENT_CATEGORY_KEY,
        },
      ],
    } as unknown as AddAttorneyDocuments,
  }),
  validations: {
    userSubmissionForm: {
      items: {
        firmName: {
          required,
        },
      },
    },
  },
  methods: {
    handleRemoveLicenseClicked() {
      this.showAdditionalLicense = false
      this.userSubmissionForm.items.additonallicenseyear2 = ""
      this.userSubmissionForm.items.additonallicensestate2 = DEFAULT_FULL_STATE_VALUE
      this.userSubmissionForm.items.additonallicensebarnumber2 = ""
    },
    handleAddLicenseClicked() {
      this.showAdditionalLicense = true
    },
    handleInvalidLicenseSnackbarClosed() {
      this.showInvalidLicenseSnackbar = false
    },
    handleExpansionValueInput(value: expansionContainerReturnValue) {
      if (value.type === "explanation") {
        this.userSubmissionForm.items[value.key] = value.value as string
      }
      if (value.type === "document") {
        this.userSubmissionDocuments[value.key] = value.value
      }
    },
    handleSubmitIfLicenseInformationProvided() {
      this.invalidLicenseInformation = false

      const licenseInformationValid =
        (!!this.userSubmissionForm.items.yearnorthcarolina && !!this.userSubmissionForm.items.barnumbernorthcarolina) ||
        (!!this.userSubmissionForm.items.additonallicenseyear &&
          !!this.userSubmissionForm.items.additonallicensestate &&
          !!this.userSubmissionForm.items.additionallicensebarnumber)

      if (licenseInformationValid) {
        this.handleSubmit()
      } else {
        const licenseInfo = this.$refs.licenseInfo as Vue
        const licenseInfoElement = licenseInfo.$el as HTMLElement

        scrollToElement(licenseInfoElement)

        this.invalidLicenseInformation = true
        this.showInvalidLicenseSnackbar = true
      }
    },
  },
})
