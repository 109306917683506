<template>
  <div>
    <div id="formio" class="container mt-5"></div>
    <div id="printableFormio" class="container mt-5"></div>
  </div>
</template>

<script>
import { EventBus } from "@/util/eventBus"

export default {
  name: "ReissueForm",
  props: {
    policyKey: {
      type: String,
      required: true,
    },
    keycloakToken: {
      type: String,
      required: true,
    },
  },
  mounted() {
    window.policyKey = this.policyKey
    const timestamp = new Date().getTime()
    const script = document.createElement("script")
    script.type = "module"
    script.id = "formioJS"
    script.src = `https://app.lawyersmutualnc.com/index-form.js?v=${timestamp}`
    document.head.appendChild(script)
    window.token = this.keycloakToken
    this.sendToken(this.keycloakToken)
    EventBus.$on("token-updated", updatedToken => {
      this.sendToken(updatedToken)
    })
  },
  beforeDestroy() {
    EventBus.$off("token-updated")
  },
  methods: {
    async sendToken(token) {
      try {
        const targetOrigin = "https://myportal.lawyersmutualnc.com"
        window.postMessage({ type: "tokenUpdate", token }, targetOrigin)
      } catch (error) {
        console.error("Failed to send token:", error)
      }
    },
  },
}
</script>

<style scoped>
@import "https://stackpath.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css";
@import "https://cdn.jsdelivr.net/npm/bootstrap@4.6.0/dist/css/bootstrap.min.css";
@import "@/styles/formio.full.css";
</style>
