<template>
  <div v-if="isLoading" class="loading-spinner-container">
    <v-progress-circular indeterminate color="primary" :size="90"></v-progress-circular>
  </div>
  <div v-else>
    <div>
      <div v-if="policies !== null && policies.length > 0">
        <div v-for="(policy, index) in policies" :key="policy.policyKey" class="policy-item">
          <ReissueForm :policy-key="policy.policyKey" :keycloak-token="keycloakToken" />
          <v-divider v-if="index !== policies.length - 1"></v-divider>
        </div>
      </div>
      <p v-else-if="policies !== null && policies.length === 0" class="empty-list-message">No policies found</p>
    </div>
  </div>
</template>

<script>
import Vue from "vue"
import { safelyDoWithUserInfo } from "@/util/doWithUserInfo"
import { getClientKeyFromUserOrUrl } from "@/util/getClientKeyFromUserOrUrl"
import { getAllPoliciesByClientKey } from "@/services/policyService"
import { getKeycloak } from "@/keycloak/keycloak"
import ReissueForm from "../Forms/ReissueForm.vue"

export default Vue.extend({
  name: "ReissueFormComponent",
  components: { ReissueForm },
  props: {
    isUser: {
      type: Boolean,
      default: null,
    },
    isClientAdmin: {
      type: Boolean,
      default: null,
    },
    currentUser: {
      type: Object,
      default: null,
    },
  },
  data: () => ({
    error: false,
    isLoading: true,
    policies: null,
    keycloakToken: null,
    clientKey: null,
    showMessageSubmittedConfirmation: false,
  }),
  mounted() {
    this.$nextTick(async () => {
      safelyDoWithUserInfo(async user => {
        this.clientKey = getClientKeyFromUserOrUrl(this, user)
        await this.initialize()
      })
    })
  },
  methods: {
    async initialize() {
      this.policies = await this.fetchClientPolicies()
      this.keycloakToken = getKeycloak().token
      this.isLoading = false
    },

    async fetchClientPolicies() {
      const response = await getAllPoliciesByClientKey(this.clientKey)
      return response
    },
  },
})
</script>

<style scoped lang="scss">
.policy-dashboard-container {
  height: 100%;
  .policy-item {
    margin-bottom: 3rem;
  }
}
.loading-spinner-container {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.empty-list-message {
  text-align: center;
  font-size: 2rem;
}
</style>
